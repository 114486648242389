import { Capacitor } from '@capacitor/core';
import { app } from '@/main';
import { AuthActionTypes } from '@/store/modules/auth/actions';
import { EVENTS } from '@/utils/events';
import { SOCIAL_LOGIN_PLATFORMS } from '@/utils/social-login';
import { CONSENT_UPDATE_EVENT_NAME, getAllGrantedCookieConsentValues, rejectCookieConsent } from '@/utils/cookie-consent';
import { hasAds } from '@/services/ads';

export { initNativeAppAds } from './native-app/native-app-ads';

// Legacy App
export const TO_NATIVE_APP_EVENTS = {
  LOGIN: 'NATIVE_APP/LOGIN',
  LOGOUT: 'NATIVE_APP/LOGOUT',
  SOCIAL_LOGIN: 'NATIVE_APP/SOCIAL_LOGIN',
};

export const FROM_NATIVE_APP_EVENTS = {
  SET_NOTIFICATION_TOKEN: 'NATIVE_APP/SET_NOTIFICATION_TOKEN',
  NOTIFICATION_RECEIVED: 'NATIVE_APP/NOTIFICATION_RECEIVED',
  SOCIAL_LOGIN_SUCCESS: 'NATIVE_APP/SOCIAL_LOGIN_SUCCESS',
  CONSENT_COOKIES: 'NATIVE_APP/CONSENT_COOKIES',
};

export const isLegacyNativeApp = (): boolean => Boolean(window?.webkit?.messageHandlers?.cordova_iab?.postMessage);
// End Legacy App

export const isNativeApp = Capacitor.isNativePlatform();
export const platform = Capacitor.getPlatform();
export const NATIVES_PLATFORMS = {
  ios: 'ios',
  android: 'android',
};

export const isIosNativeApp = platform === NATIVES_PLATFORMS.ios;
export const isAndroidNativeApp = platform === NATIVES_PLATFORMS.android;

export const onTrackingAuthorized = (): void => {
  const values = getAllGrantedCookieConsentValues();
  app.pushCookieConsentDataLayer('consent', 'update', values);
  app.sendAnalyticsEvent(CONSENT_UPDATE_EVENT_NAME, values);

  app.$store.dispatch(AuthActionTypes.ACCEPT_COOKIES);
};

export const onTrackingRejected = (): void => {
  rejectCookieConsent();
};

// eslint-disable-next-line
export const getAdmob = () => import('@capacitor-community/admob');

const getPushNotifications = () => import('@capacitor/push-notifications');

export const initPushNotifications = async (): Promise<void> => {
  const { PushNotifications } = await getPushNotifications();

  await PushNotifications.addListener('registration', token => {
    app.$store.dispatch(AuthActionTypes.UPDATE_FIREBASE_TOKEN, {userId: app.userId, token: token.value});
  });

  // await PushNotifications.addListener('registrationError', err => {
  //   console.error('Registration error: ', err.error);
  // });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    const {title, body} = notification;
    app.showToast(title, body);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    if (notification.actionId === 'tap') {
      const { data } = notification.notification;

      let url = data && data.url ? data.url : null;
      if (url === 'chat_channels') {
        url = '/messages';
      }

      if (url) {
        app.routerPathPush(url.replace(process.env.VUE_APP_PWA_URL, '/'));
      }
    }
  });
};

export const registerPushNotifications = async (): Promise<void> => {
  const { PushNotifications } = await getPushNotifications();

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    await PushNotifications.register();
  }
};

export const unregisterPushNotifications = async (): Promise<void> => {
  const { PushNotifications } = await getPushNotifications();
  await PushNotifications.unregister();
};

const getShare = () => import('@capacitor/share');

export const share = async ({url, title, text}: {url?: string, title?: string, text?: string}): Promise<void> => {
  const { Share } = await getShare();
  Share.share({url, title, text});
};

const getGoogleAuth = () => import('@codetrix-studio/capacitor-google-auth');

export const socialLoginSignIn = async (platform: string): Promise<void> => {
  if (platform === SOCIAL_LOGIN_PLATFORMS.GOOGLE) {
    const { GoogleAuth } = await getGoogleAuth();

    GoogleAuth.initialize();
    GoogleAuth.signIn()
      .then(user => {
        app.$root.$emit(EVENTS.SOCIAL_LOGIN, {platform , data: user.authentication.idToken});
      })
      .catch(() => {
        // Nothing to do
      });
  }
};

const getApp = () => import('@capacitor/app');

export const getAppVersion = async (): Promise<string> => {
  const { App } = await getApp();
  const info = await App.getInfo();

  return info.version;
};

export const exitApp = async (): Promise<void> => {
  const { App } = await getApp();

  return App.exitApp();
};

export const initAppUrlOpen = async (): Promise<void> => {
  const { App } = await getApp();

  App.addListener('appUrlOpen', event => {
    const url = event.url;
    const appOpenUrl = url ? url.replace(process.env.VUE_APP_PWA_URL, '/') : null;

    if (appOpenUrl && appOpenUrl.charAt(0) === '/') {
      app.routerPathPush(appOpenUrl);
    }

  });
};

const getIosAppTrackingTransparency = () => import('capacitor-plugin-app-tracking-transparency');

export const isTrackingTransparencyAllowed = async (): Promise<boolean> => {
  let status = 'authorized';
  if (isIosNativeApp) {
    const { AppTrackingTransparency } = await getIosAppTrackingTransparency();

    const appTrackingTransparency = await AppTrackingTransparency.getStatus();
    status = appTrackingTransparency.status;
    if (status === 'notDetermined') {
      const requestPermission = await AppTrackingTransparency.requestPermission();
      status = requestPermission.status;
    }
  }

  return status === 'authorized';
};

const getAppLauncher = () => import('@capacitor/app-launcher');

export const canOpenUrl = async (url: string): Promise<boolean> => {
  const { AppLauncher } = await getAppLauncher();

  let result = false;
  try {
    const { value } = await AppLauncher.canOpenUrl({url});
    result = value;
  } catch (e) {
    result = false;
  }

  return result;
};

export const openUrl = async (url: string): Promise<boolean> => {
  const { AppLauncher } = await getAppLauncher();

  let result = false;
  try {
    const { completed } = await AppLauncher.openUrl({url});
    result = completed;
  } catch (e) {
    result = false;
  }

  return result;
};

// eslint-disable-next-line
const getInAppBrowser = () => import('@awesome-cordova-plugins/in-app-browser');

export const openNativeAppLink = async (url: string): Promise<void> => {
  const { InAppBrowser } = await getInAppBrowser();
  InAppBrowser.create(url, '_system')
};

export const loadAllNativeLibraries = (): Promise<boolean> => {
  const libraries: Promise<any>[] = [
    getApp(),
    getPushNotifications(),
    getGoogleAuth(),
    getAppLauncher(),
    getInAppBrowser(),
  ];

  if (hasAds) {
    libraries.push(getAdmob());
  }

  if (isIosNativeApp) {
    libraries.push(getIosAppTrackingTransparency());
  } else {
    libraries.push(getShare());
  }

  return Promise.all(libraries).then(() => true).catch(() => false);
};
